var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Progress } from './Progress';
var isCurrentDayStatRow = function (isLatestGame, isGameWon, numberOfGuessesMade, i) {
    return isLatestGame && isGameWon && numberOfGuessesMade === i + 1;
};
export var Histogram = function (_a) {
    var gameStats = _a.gameStats, isLatestGame = _a.isLatestGame, isGameWon = _a.isGameWon, numberOfGuessesMade = _a.numberOfGuessesMade;
    var winDistribution = gameStats.winDistribution;
    var maxValue = Math.max.apply(Math, __spreadArray(__spreadArray([], winDistribution, false), [1], false));
    return (_jsx("div", { className: "justify-left m-2 columns-1 text-sm dark:text-white", children: winDistribution.map(function (value, i) { return (_jsx(Progress, { index: i, isCurrentDayStatRow: isCurrentDayStatRow(isLatestGame, isGameWon, numberOfGuessesMade, i), size: 90 * (value / maxValue), label: String(value) }, i)); }) }));
};
