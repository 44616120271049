import { jsx as _jsx } from "react/jsx-runtime";
import classnames from 'classnames';
import { REVEAL_TIME_MS } from '../../constants/settings';
import { getStoredIsHighContrastMode } from '../../lib/localStorage';
export var Cell = function (_a) {
    var value = _a.value, status = _a.status, isRevealing = _a.isRevealing, isCompleted = _a.isCompleted, _b = _a.position, position = _b === void 0 ? 0 : _b;
    var isFilled = value && !isCompleted;
    var shouldReveal = isRevealing && isCompleted;
    var animationDelay = "".concat(position * REVEAL_TIME_MS, "ms");
    var isHighContrast = getStoredIsHighContrastMode();
    var classes = classnames('xxshort:w-11 xxshort:h-11 short:text-2xl short:w-12 short:h-12 w-14 h-14 border-solid border-2 flex items-center justify-center mx-0.5 text-4xl font-bold rounded dark:text-white', {
        'bg-white dark:bg-slate-900 border-slate-200 dark:border-slate-600': !status,
        'border-black dark:border-slate-100': value && !status,
        'absent shadowed bg-slate-400 dark:bg-slate-700 text-white border-slate-400 dark:border-slate-700': status === 'absent',
        'correct shadowed bg-orange-500 text-white border-orange-500': status === 'correct' && isHighContrast,
        'present shadowed bg-cyan-500 text-white border-cyan-500': status === 'present' && isHighContrast,
        'correct shadowed bg-green-500 text-white border-green-500': status === 'correct' && !isHighContrast,
        'present shadowed bg-yellow-500 text-white border-yellow-500': status === 'present' && !isHighContrast,
        'cell-fill-animation': isFilled,
        'cell-reveal': shouldReveal,
    });
    return (_jsx("div", { className: classes, style: { animationDelay: animationDelay }, children: _jsx("div", { className: "letter-container", style: { animationDelay: animationDelay }, children: value }) }));
};
