var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './App.css';
import { ClockIcon } from '@heroicons/react/outline';
import { format } from 'date-fns';
import { ReCaptchaV3Provider, initializeAppCheck } from 'firebase/app-check';
import { default as GraphemeSplitter } from 'grapheme-splitter';
import { useCallback, useEffect, useState } from 'react';
import Div100vh from 'react-div-100vh';
import { AlertContainer } from './components/alerts/AlertContainer';
import CheckForHour from './components/check-for-hour/CheckForHour';
import { Grid } from './components/grid/Grid';
import { Keyboard } from './components/keyboard/Keyboard';
import { DatePickerModal } from './components/modals/DatePickerModal';
import { InfoModal } from './components/modals/InfoModal';
import { MigrateStatsModal } from './components/modals/MigrateStatsModal';
import { SettingsModal } from './components/modals/SettingsModal';
import { StatsModal } from './components/modals/StatsModal';
import { Navbar } from './components/navbar/Navbar';
import ScoreBoardContainer from './components/score-board/ScoreBoardContainer';
import { app, initializeFirebase } from './config/firebase';
import { DATE_LOCALE, DISCOURAGE_INAPP_BROWSERS, LONG_ALERT_TIME_MS, MAX_CHALLENGES, REVEAL_TIME_MS, WELCOME_INFO_MODAL_MS, } from './constants/settings';
import { CORRECT_WORD_MESSAGE, DISCOURAGE_INAPP_BROWSER_TEXT, GAME_COPIED_MESSAGE, NOT_ENOUGH_LETTERS_MESSAGE, SHARE_FAILURE_TEXT, WORD_NOT_FOUND_MESSAGE, } from './constants/strings';
import { useAlert } from './context/AlertContext';
import useGameSettings from './hooks/useGameSettings';
import useGameStatus from './hooks/useGameStatus';
import { isInAppBrowser } from './lib/browser';
import { loadGameStateFromLocalStorage, saveGameStateToLocalStorage, } from './lib/localStorage';
import { addStatsForCompletedGame, loadStats } from './lib/stats';
import { findFirstUnusedReveal, getGameDate, getIsLatestGame, isWinningWord, isWordInWordList, setGameDate, solution, solutionGameDate, unicodeLength, } from './lib/words';
// We must initialize Firebase before using it
initializeFirebase();
initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(process.env.REACT_APP_RECAPTCHA_KEY),
    isTokenAutoRefreshEnabled: true,
});
function App() {
    var isLatestGame = getIsLatestGame();
    var gameDate = getGameDate();
    var _a = useAlert(), showErrorAlert = _a.showError, showSuccessAlert = _a.showSuccess;
    var _b = useState(''), currentGuess = _b[0], setCurrentGuess = _b[1];
    var _c = useState(false), isGameWon = _c[0], setIsGameWon = _c[1];
    var _d = useState(false), isInfoModalOpen = _d[0], setIsInfoModalOpen = _d[1];
    var _e = useState(false), isStatsModalOpen = _e[0], setIsStatsModalOpen = _e[1];
    var _f = useState(false), isDatePickerModalOpen = _f[0], setIsDatePickerModalOpen = _f[1];
    var _g = useState(false), isMigrateStatsModalOpen = _g[0], setIsMigrateStatsModalOpen = _g[1];
    var _h = useState(false), isScoreboardModalOpen = _h[0], setIsScoreboardModalOpen = _h[1];
    var _j = useState(false), isSettingsModalOpen = _j[0], setIsSettingsModalOpen = _j[1];
    var _k = useState(''), currentRowClass = _k[0], setCurrentRowClass = _k[1];
    var _l = useState(false), isGameLost = _l[0], setIsGameLost = _l[1];
    var _m = useState(false), isOneHourPassed = _m[0], setIsOneHourPassed = _m[1];
    var _o = useState(false), isRevealing = _o[0], setIsRevealing = _o[1];
    var _p = useState(function () { return loadStats(); }), stats = _p[0], setStats = _p[1];
    var _q = useState(function () {
        var loaded = loadGameStateFromLocalStorage(isLatestGame);
        if ((loaded === null || loaded === void 0 ? void 0 : loaded.solution) !== solution) {
            return [];
        }
        var gameWasWon = loaded.guesses.includes(solution);
        if (gameWasWon) {
            setIsGameWon(true);
        }
        if (loaded.guesses.length === MAX_CHALLENGES && !gameWasWon) {
            setIsGameLost(true);
            showErrorAlert(CORRECT_WORD_MESSAGE(solution), {
                persist: true,
            });
        }
        return loaded.guesses;
    }), guesses = _q[0], setGuesses = _q[1];
    useEffect(function () {
        // if no game state on load,
        // show the user the how-to info modal
        if (!loadGameStateFromLocalStorage(true)) {
            setTimeout(function () {
                setIsInfoModalOpen(true);
            }, WELCOME_INFO_MODAL_MS);
        }
    });
    useEffect(function () {
        DISCOURAGE_INAPP_BROWSERS &&
            isInAppBrowser() &&
            showErrorAlert(DISCOURAGE_INAPP_BROWSER_TEXT, {
                persist: false,
                durationMs: 7000,
            });
    }, [showErrorAlert]);
    var _r = useGameSettings(guesses, showErrorAlert), handleDarkMode = _r.handleDarkMode, handleHardMode = _r.handleHardMode, handleHighContrastMode = _r.handleHighContrastMode, isDarkMode = _r.isDarkMode, isHighContrastMode = _r.isHighContrastMode, isHardMode = _r.isHardMode;
    useGameStatus({
        isGameLost: isGameLost,
        isGameWon: isGameWon,
        setIsStatsModalOpen: setIsStatsModalOpen,
        showSuccessAlert: showSuccessAlert,
        solution: solution,
    });
    var clearCurrentRowClass = function () {
        setCurrentRowClass('');
    };
    useEffect(function () {
        saveGameStateToLocalStorage(getIsLatestGame(), {
            guesses: guesses,
            solution: solution,
        });
    }, [guesses]);
    var onChar = function (value) {
        if (unicodeLength("".concat(currentGuess).concat(value)) <= solution.length &&
            guesses.length < MAX_CHALLENGES &&
            !isGameWon) {
            setCurrentGuess("".concat(currentGuess).concat(value));
        }
    };
    var onDelete = function () {
        setCurrentGuess(new GraphemeSplitter().splitGraphemes(currentGuess).slice(0, -1).join(''));
    };
    var onEnter = function () {
        if (isGameWon || isGameLost) {
            return;
        }
        if (!(unicodeLength(currentGuess) === solution.length)) {
            setCurrentRowClass('jiggle');
            return showErrorAlert(NOT_ENOUGH_LETTERS_MESSAGE, {
                onClose: clearCurrentRowClass,
            });
        }
        if (!isWordInWordList(currentGuess)) {
            setCurrentRowClass('jiggle');
            return showErrorAlert(WORD_NOT_FOUND_MESSAGE, {
                onClose: clearCurrentRowClass,
            });
        }
        // enforce hard mode - all guesses must contain all previously revealed letters
        if (isHardMode) {
            var firstMissingReveal = findFirstUnusedReveal(currentGuess, guesses);
            if (firstMissingReveal) {
                setCurrentRowClass('jiggle');
                return showErrorAlert(firstMissingReveal, {
                    onClose: clearCurrentRowClass,
                });
            }
        }
        setIsRevealing(true);
        // turn this back off after all
        // chars have been revealed
        setTimeout(function () {
            setIsRevealing(false);
        }, REVEAL_TIME_MS * solution.length);
        var winningWord = isWinningWord(currentGuess);
        if (unicodeLength(currentGuess) === solution.length &&
            guesses.length < MAX_CHALLENGES &&
            !isGameWon) {
            setGuesses(__spreadArray(__spreadArray([], guesses, true), [currentGuess], false));
            setCurrentGuess('');
            if (winningWord) {
                if (isLatestGame) {
                    setStats(addStatsForCompletedGame(stats, guesses.length));
                }
                return setIsGameWon(true);
            }
            if (guesses.length === MAX_CHALLENGES - 1) {
                if (isLatestGame) {
                    setStats(addStatsForCompletedGame(stats, guesses.length + 1));
                }
                setIsGameLost(true);
                showErrorAlert(CORRECT_WORD_MESSAGE(solution), {
                    persist: true,
                    delayMs: REVEAL_TIME_MS * solution.length + 1,
                });
            }
        }
    };
    var handeGenerateNewSolution = useCallback(function () {
        if (!isOneHourPassed) {
            return;
        }
        window.location.reload();
    }, [isOneHourPassed]);
    return (_jsx(CheckForHour, { setIsOneHourPassed: setIsOneHourPassed, children: _jsx(Div100vh, { children: _jsxs("div", { className: "flex h-full flex-col", children: [_jsx(Navbar, { setIsScoreboardModalOpen: setIsScoreboardModalOpen, setIsInfoModalOpen: setIsInfoModalOpen, setIsStatsModalOpen: setIsStatsModalOpen, setIsDatePickerModalOpen: setIsDatePickerModalOpen, setIsSettingsModalOpen: setIsSettingsModalOpen, isDarkMode: isDarkMode }), _jsx(ScoreBoardContainer, { handleClose: function () { return setIsScoreboardModalOpen(false); }, isOpen: isScoreboardModalOpen }), !isLatestGame && (_jsxs("div", { className: "flex items-center justify-center", children: [_jsx(ClockIcon, { className: "h-6 w-6 stroke-gray-600 dark:stroke-gray-300" }), _jsx("p", { className: "text-base text-gray-600 dark:text-gray-300", children: format(gameDate, 'd MMMM yyyy', { locale: DATE_LOCALE }) })] })), _jsxs("div", { className: "mx-auto flex w-full grow flex-col px-1 pt-2 pb-8 sm:px-6 md:max-w-7xl lg:px-8 short:pb-2 short:pt-2", children: [_jsx("div", { className: "flex grow flex-col justify-center pb-6 short:pb-2", children: _jsx(Grid, { solution: solution, guesses: guesses, currentGuess: currentGuess, isRevealing: isRevealing, currentRowClassName: currentRowClass }) }), _jsx(Keyboard, { isStatsModalOpen: isStatsModalOpen, onChar: onChar, onDelete: onDelete, onEnter: onEnter, solution: solution, guesses: guesses, isRevealing: isRevealing }), _jsx(InfoModal, { isOpen: isInfoModalOpen, handleClose: function () { return setIsInfoModalOpen(false); } }), _jsx(StatsModal, { isOneHourPassed: isOneHourPassed, isOpen: isStatsModalOpen, handleClose: function () { return setIsStatsModalOpen(false); }, handleGenerateNewSolution: handeGenerateNewSolution, solution: solution, guesses: guesses, gameStats: stats, isLatestGame: isLatestGame, isGameLost: isGameLost, isGameWon: isGameWon, handleShareToClipboard: function () {
                                    return showSuccessAlert(GAME_COPIED_MESSAGE);
                                }, handleShareFailure: function () {
                                    return showErrorAlert(SHARE_FAILURE_TEXT, {
                                        durationMs: LONG_ALERT_TIME_MS,
                                    });
                                }, handleMigrateStatsButton: function () {
                                    setIsStatsModalOpen(false);
                                    setIsMigrateStatsModalOpen(true);
                                }, isHardMode: isHardMode, isDarkMode: isDarkMode, isHighContrastMode: isHighContrastMode, numberOfGuessesMade: guesses.length }), _jsx(DatePickerModal, { isOpen: isDatePickerModalOpen, initialDate: solutionGameDate, handleSelectDate: function (d) {
                                    setIsDatePickerModalOpen(false);
                                    setGameDate(d);
                                }, handleClose: function () { return setIsDatePickerModalOpen(false); } }), _jsx(MigrateStatsModal, { isOpen: isMigrateStatsModalOpen, handleClose: function () { return setIsMigrateStatsModalOpen(false); } }), _jsx(SettingsModal, { isOpen: isSettingsModalOpen, handleClose: function () { return setIsSettingsModalOpen(false); }, isHardMode: isHardMode, handleHardMode: handleHardMode, isDarkMode: isDarkMode, handleDarkMode: handleDarkMode, isHighContrastMode: isHighContrastMode, handleHighContrastMode: handleHighContrastMode }), _jsx(AlertContainer, {})] })] }) }) }));
}
export default App;
