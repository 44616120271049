import { MAX_LENGTH, MIN_LENGTH } from '../constants';
var errorMessages = {
    minLength: function (field) {
        return "".concat(field, " must be at least ").concat(MIN_LENGTH, " characters long");
    },
    maxLength: function (field) {
        return "".concat(field, " must be at most ").concat(MAX_LENGTH, " characters long");
    },
    invalidEmail: function () { return 'Invalid email address'; },
    nonNegativeNumber: function (field) {
        return "".concat(field, " must be a non-negative number");
    },
};
export { errorMessages };
