import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { DELETE_TEXT, ENTER_TEXT } from '../../constants/strings';
import { getStatuses } from '../../lib/statuses';
import { localeAwareUpperCase } from '../../lib/words';
import { Key } from './Key';
export var Keyboard = function (_a) {
    var onChar = _a.onChar, onDelete = _a.onDelete, onEnter = _a.onEnter, solution = _a.solution, guesses = _a.guesses, isRevealing = _a.isRevealing, isStatsModalOpen = _a.isStatsModalOpen;
    var charStatuses = getStatuses(solution, guesses);
    var onClick = function (value) {
        if (isStatsModalOpen)
            return;
        if (value === 'ENTER') {
            onEnter();
        }
        else if (value === 'DELETE') {
            onDelete();
        }
        else {
            onChar(value);
        }
    };
    useEffect(function () {
        if (isStatsModalOpen)
            return;
        var listener = function (e) {
            if (e.code === 'Enter') {
                onEnter();
            }
            else if (e.code === 'Backspace') {
                onDelete();
            }
            else {
                var key = localeAwareUpperCase(e.key);
                // TODO: check this test if the range works with non-english letters
                if (key.length === 1 && key >= 'A' && key <= 'Z') {
                    onChar(key);
                }
            }
        };
        window.addEventListener('keyup', listener);
        return function () {
            window.removeEventListener('keyup', listener);
        };
    }, [onEnter, onDelete, onChar, isStatsModalOpen]);
    return (_jsxs("div", { children: [_jsx("div", { className: "mb-1 flex justify-center", children: ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'].map(function (key) { return (_jsx(Key, { value: key, onClick: onClick, status: charStatuses[key], isRevealing: isRevealing }, key)); }) }), _jsx("div", { className: "mb-1 flex justify-center", children: ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'].map(function (key) { return (_jsx(Key, { value: key, onClick: onClick, status: charStatuses[key], isRevealing: isRevealing }, key)); }) }), _jsxs("div", { className: "flex justify-center", children: [_jsx(Key, { width: 65.4, value: "ENTER", onClick: onClick, children: ENTER_TEXT }), ['Z', 'X', 'C', 'V', 'B', 'N', 'M'].map(function (key) { return (_jsx(Key, { value: key, onClick: onClick, status: charStatuses[key], isRevealing: isRevealing }, key)); }), _jsx(Key, { width: 65.4, value: "DELETE", onClick: onClick, children: DELETE_TEXT })] })] }));
};
