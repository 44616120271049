import { jsx as _jsx } from "react/jsx-runtime";
import classnames from 'classnames';
import { REVEAL_TIME_MS } from '../../constants/settings';
import { getStoredIsHighContrastMode } from '../../lib/localStorage';
import { solution } from '../../lib/words';
export var Key = function (_a) {
    var children = _a.children, status = _a.status, _b = _a.width, width = _b === void 0 ? 40 : _b, value = _a.value, onClick = _a.onClick, isRevealing = _a.isRevealing;
    var keyDelayMs = REVEAL_TIME_MS * solution.length;
    var isHighContrast = getStoredIsHighContrastMode();
    var classes = classnames('xxshort:h-8 xxshort:w-8 xxshort:text-xxs xshort:w-10 xshort:h-10 flex short:h-12 h-14 items-center justify-center rounded mx-0.5 text-xs font-bold cursor-pointer select-none dark:text-white', {
        'transition ease-in-out': isRevealing,
        'bg-slate-200 dark:bg-slate-600 hover:bg-slate-300 active:bg-slate-400': !status,
        'bg-slate-400 dark:bg-slate-800 text-white': status === 'absent',
        'bg-orange-500 hover:bg-orange-600 active:bg-orange-700 text-white': status === 'correct' && isHighContrast,
        'bg-cyan-500 hover:bg-cyan-600 active:bg-cyan-700 text-white': status === 'present' && isHighContrast,
        'bg-green-500 hover:bg-green-600 active:bg-green-700 text-white': status === 'correct' && !isHighContrast,
        'bg-yellow-500 hover:bg-yellow-600 active:bg-yellow-700 text-white': status === 'present' && !isHighContrast,
    });
    var styles = {
        transitionDelay: isRevealing ? "".concat(keyDelayMs, "ms") : 'unset',
        width: "".concat(width, "px"),
    };
    var handleClick = function (event) {
        onClick(value);
        event.currentTarget.blur();
    };
    return (_jsx("button", { style: styles, "aria-label": "".concat(value).concat(status ? ' ' + status : ''), className: classes, onClick: handleClick, children: children || value }));
};
