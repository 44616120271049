import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { EmigratePanel } from '../stats/EmigratePanel';
import { ImmigratePanel } from '../stats/ImmigratePanel';
import { BaseModal } from './BaseModal';
export var MigrateStatsModal = function (_a) {
    var isOpen = _a.isOpen, handleClose = _a.handleClose;
    var _b = useState(true), isEmigrateVisible = _b[0], setIsEmigrateVisible = _b[1];
    return (_jsxs(BaseModal, { title: "Transfer your statistics", isOpen: isOpen, handleClose: handleClose, children: [_jsx("p", { className: "mt-4 mb-4 text-sm text-gray-500 dark:text-gray-300", children: "Copy the migration code on your old device and paste into the input on the new device." }), _jsxs("div", { className: "w-full columns-3 gap-0", children: [_jsx("div", { className: "mb-4 flex items-center", children: _jsx("p", { className: "mb-0 flex text-sm font-medium text-gray-900 dark:text-gray-300", children: "This is my:" }) }), _jsxs("div", { className: "mb-4 flex items-center", children: [_jsx("input", { checked: isEmigrateVisible, onChange: function () { return setIsEmigrateVisible(true); }, id: "emigrate-radio-button", radioGroup: "migrate-radio-buttons", type: "radio", value: "", name: "emigrate-radio-button", className: "h-4 w-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600" }), _jsx("label", { htmlFor: "emigrate-radio-button", className: "ml-2 text-sm font-medium text-gray-900 dark:text-gray-300", children: "old device" })] }), _jsxs("div", { className: "flex items-center", children: [_jsx("input", { checked: !isEmigrateVisible, onChange: function () { return setIsEmigrateVisible(false); }, id: "immigrate-radio-button", radioGroup: "migrate-radio-buttons", type: "radio", value: "", name: "immigrate-radio-button", className: "h-4 w-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:ring-offset-gray-800 dark:focus:ring-blue-600" }), _jsx("label", { htmlFor: "immigrate-radio-button", className: "ml-2 text-sm font-medium text-gray-900 dark:text-gray-300", children: "new device" })] })] }), isEmigrateVisible && _jsx(EmigratePanel, {}), !isEmigrateVisible && _jsx(ImmigratePanel, {})] }));
};
