export var LAST_WORD = 'lastWord';
export var LAST_WORD_DATE = 'lastWordDate';
export var USED_INDEXES = 'usedIndexes';
export var drawWord = function (_a) {
    var wordIndex = _a.wordIndex, currentDate = _a.currentDate, usedIndexes = _a.usedIndexes, newWord = _a.newWord;
    localStorage.setItem(LAST_WORD, newWord);
    localStorage.setItem(LAST_WORD_DATE, currentDate.toString());
    usedIndexes.push(wordIndex);
    localStorage.setItem(USED_INDEXES, JSON.stringify(usedIndexes));
    return newWord;
};
export var hasOneHourPassed = function () {
    var lastWordDate = localStorage.getItem(LAST_WORD_DATE);
    if (!lastWordDate) {
        return localStorage.setItem(LAST_WORD_DATE, new Date().toISOString());
    }
    var lastWordDateObj = new Date(lastWordDate);
    var currentDate = new Date();
    var lastWordHour = lastWordDateObj.getHours();
    var currentHour = currentDate.getHours();
    return currentHour !== lastWordHour;
};
