import * as firebase from 'firebase/app';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
var firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_FIREBASE_APPID,
};
var app = firebase.initializeApp(firebaseConfig);
var initializeFirebase = function () {
    var HOST = process.env.REACT_APP_FUNCTIONS_EMULATOR_HOST_NAME;
    var PORT = Number(process.env.REACT_APP_FUNCTIONS_EMULATOR_PORT);
    if (process.env.NODE_ENV === 'development') {
        var functions = getFunctions(app);
        connectFunctionsEmulator(functions, HOST, PORT);
    }
};
export { app, initializeFirebase };
