import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { MAX_CHALLENGES } from '../../constants/settings';
import { CompletedRow } from './CompletedRow';
import { CurrentRow } from './CurrentRow';
import { EmptyRow } from './EmptyRow';
export var Grid = function (_a) {
    var solution = _a.solution, guesses = _a.guesses, currentGuess = _a.currentGuess, isRevealing = _a.isRevealing, currentRowClassName = _a.currentRowClassName;
    var empties = guesses.length < MAX_CHALLENGES - 1
        ? Array.from(Array(MAX_CHALLENGES - 1 - guesses.length))
        : [];
    return (_jsxs(_Fragment, { children: [guesses.map(function (guess, i) { return (_jsx(CompletedRow, { solution: solution, guess: guess, isRevealing: isRevealing && guesses.length - 1 === i }, i)); }), guesses.length < MAX_CHALLENGES && (_jsx(CurrentRow, { guess: currentGuess, className: currentRowClassName })), empties.map(function (_, i) { return (_jsx(EmptyRow, {}, i)); })] }));
};
