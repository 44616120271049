import Blowfish from 'egoroof-blowfish';
import { BLOWFISH_IV, BLOWFISH_KEY } from '../constants/settings';
// https://github.com/egoroof/blowfish/blob/master/README.md
var bf = new Blowfish(BLOWFISH_KEY, Blowfish.MODE.ECB, Blowfish.PADDING.NULL);
bf.setIv(BLOWFISH_IV);
export var encrypt = function (data) {
    return btoa(bf.encode(data).reduce(function (data, byte) { return data + String.fromCharCode(byte); }, ''));
};
export var decrypt = function (encoded) {
    try {
        return bf.decode(Uint8Array.from(atob(encoded), function (c) { return c.charCodeAt(0); }), Blowfish.TYPE.STRING);
    }
    catch (error) {
        return null;
    }
};
