import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { addDays, format, startOfDay } from 'date-fns';
import { useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { DATE_LOCALE } from '../../constants/settings';
import { DATEPICKER_CHOOSE_TEXT, DATEPICKER_TITLE, DATEPICKER_TODAY_TEXT, } from '../../constants/strings';
import { getToday, getYesterday } from '../../lib/dateutils';
import { firstGameDate, getLastGameDate, isValidGameDate, periodInDays, } from '../../lib/words';
import { BaseModal } from './BaseModal';
export var DatePickerModal = function (_a) {
    var isOpen = _a.isOpen, initialDate = _a.initialDate, handleSelectDate = _a.handleSelectDate, handleClose = _a.handleClose;
    var lastGameDate = getLastGameDate(getYesterday());
    var _b = useState(function () {
        if (initialDate == null || initialDate > lastGameDate) {
            return lastGameDate;
        }
        return initialDate;
    }), selectedDate = _b[0], setSelectedDate = _b[1];
    var headingDateFormat = 'MMMM yyyy';
    var buttonDateFormat = 'd MMM yyyy';
    var formatOptions = { locale: DATE_LOCALE };
    registerLocale('locale', DATE_LOCALE);
    var excludedDates = [];
    if (periodInDays > 1) {
        var date = firstGameDate;
        for (date = firstGameDate; date < getToday(); date = addDays(date, 1)) {
            if (!isValidGameDate(date)) {
                excludedDates.push(date);
            }
        }
    }
    return (_jsxs(BaseModal, { title: DATEPICKER_TITLE, isOpen: isOpen, handleClose: handleClose, children: [_jsx("div", { className: "mx-auto flex max-w-2xl items-center justify-center space-x-4 py-5 text-left sm:w-48", children: _jsx(DatePicker, { locale: "locale", minDate: firstGameDate, maxDate: getYesterday(), selected: selectedDate, excludeDates: excludedDates, onChange: function (date) { return setSelectedDate(startOfDay(date)); }, inline: true, popperClassName: "react-datepicker-left", renderCustomHeader: function (_a) {
                        var date = _a.date, decreaseMonth = _a.decreaseMonth, increaseMonth = _a.increaseMonth, prevMonthButtonDisabled = _a.prevMonthButtonDisabled, nextMonthButtonDisabled = _a.nextMonthButtonDisabled;
                        return (_jsxs("div", { className: "flex items-center justify-between px-2 py-2", children: [_jsx("span", { className: "text-lg text-gray-700 dark:text-gray-100", children: format(date, headingDateFormat, formatOptions) }), _jsxs("div", { className: "space-x-2", children: [_jsx("button", { onClick: decreaseMonth, disabled: prevMonthButtonDisabled, type: "button", className: "\n                            ".concat(prevMonthButtonDisabled &&
                                                'cursor-not-allowed opacity-50', "\n                            inline-flex rounded border border-gray-300 bg-white p-1 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-0\n                            dark:border-gray-600 dark:bg-slate-700 dark:text-gray-200 dark:focus:ring-blue-600\n                        "), children: _jsx(ChevronLeftIcon, { className: "h-5 w-5 text-gray-600 dark:text-gray-300" }) }), _jsx("button", { onClick: increaseMonth, disabled: nextMonthButtonDisabled, type: "button", className: "\n                            ".concat(nextMonthButtonDisabled &&
                                                'cursor-not-allowed opacity-50', "\n                            inline-flex rounded border border-gray-300 bg-white p-1 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-0\n                            dark:border-gray-600 dark:bg-slate-700 dark:text-gray-200 dark:focus:ring-blue-600\n                        "), children: _jsx(ChevronRightIcon, { className: "h-5 w-5 text-gray-600 dark:text-gray-300" }) })] })] }));
                    } }) }), _jsxs("div", { className: "mt-5 flex columns-2 items-center items-stretch justify-center gap-2 text-center dark:text-white sm:mt-6", children: [_jsxs("button", { type: "button", disabled: !isValidGameDate(getToday()), className: "mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:border-gray-200 disabled:bg-gray-500 disabled:bg-white disabled:text-gray-900\n          disabled:focus:outline-none disabled:dark:border-gray-600 disabled:dark:bg-gray-800 disabled:dark:text-gray-400 sm:text-base sm:text-base", onClick: function () { return handleSelectDate(getToday()); }, children: [DATEPICKER_CHOOSE_TEXT, " ", DATEPICKER_TODAY_TEXT] }), _jsxs("button", { type: "button", className: "mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ", disabled: selectedDate >= getToday(), onClick: function () { return handleSelectDate(selectedDate); }, children: [DATEPICKER_CHOOSE_TEXT, _jsx("br", {}), format(selectedDate, buttonDateFormat, formatOptions)] })] })] }));
};
