import { unicodeSplit } from './words';
export var getStatuses = function (solution, guesses) {
    var charObj = {};
    var splitSolution = unicodeSplit(solution);
    guesses.forEach(function (word) {
        unicodeSplit(word).forEach(function (letter, i) {
            if (!splitSolution.includes(letter)) {
                // make status absent
                return (charObj[letter] = 'absent');
            }
            if (letter === splitSolution[i]) {
                //make status correct
                return (charObj[letter] = 'correct');
            }
            if (charObj[letter] !== 'correct') {
                //make status present
                return (charObj[letter] = 'present');
            }
        });
    });
    return charObj;
};
export var getGuessStatuses = function (solution, guess) {
    var splitSolution = unicodeSplit(solution);
    var splitGuess = unicodeSplit(guess);
    var solutionCharsTaken = splitSolution.map(function (_) { return false; });
    var statuses = Array.from(Array(guess.length));
    // handle all correct cases first
    splitGuess.forEach(function (letter, i) {
        if (letter === splitSolution[i]) {
            statuses[i] = 'correct';
            solutionCharsTaken[i] = true;
            return;
        }
    });
    splitGuess.forEach(function (letter, i) {
        if (statuses[i])
            return;
        if (!splitSolution.includes(letter)) {
            // handles the absent case
            statuses[i] = 'absent';
            return;
        }
        // now we are left with "present"s
        var indexOfPresentChar = splitSolution.findIndex(function (x, index) { return x === letter && !solutionCharsTaken[index]; });
        if (indexOfPresentChar > -1) {
            statuses[i] = 'present';
            solutionCharsTaken[indexOfPresentChar] = true;
            return;
        }
        else {
            statuses[i] = 'absent';
            return;
        }
    });
    return statuses;
};
