import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { playMoreGames } from '@/src/constants/messages';
import { MAX_GAMES_ALLOWED } from '@/src/constants/settings';
import Button from '../button/Button';
import SetScoreForm from '../form/SetScoreForm';
var StatsModalActions = function (_a) {
    var totalGames = _a.totalGames, successRate = _a.successRate, isOneHourPassed = _a.isOneHourPassed, isGameWon = _a.isGameWon, isGameLost = _a.isGameLost, handleGenerateNewSolution = _a.handleGenerateNewSolution;
    var hasReachedMaxGames = totalGames >= MAX_GAMES_ALLOWED;
    return (_jsxs(_Fragment, { children: [hasReachedMaxGames && (_jsx(SetScoreForm, { score: successRate, className: "mt-4" })), !hasReachedMaxGames && (isGameWon || isGameLost) && (_jsx(Button, { disabled: !isOneHourPassed, className: "mt-6", onClick: handleGenerateNewSolution, children: playMoreGames[totalGames - 1] }))] }));
};
export default StatsModalActions;
