import { UAParser } from 'ua-parser-js';
var inAppBrowserNames = [
    'Facebook',
    'Instagram',
    'Line',
    'Messenger',
    'Puffin',
    'Twitter',
    'WeChat',
];
var parser = new UAParser();
var browser = parser.getBrowser();
export var isInAppBrowser = function () {
    var _a;
    return inAppBrowserNames.indexOf((_a = browser.name) !== null && _a !== void 0 ? _a : '') > -1;
};
