import { z } from 'zod';
import { MAX_LENGTH, MIN_LENGTH } from '../constants';
import { errorMessages } from './error-messages';
export var UserSchema = z.object({
    id: z.string().optional(),
    name: z
        .string()
        .min(MIN_LENGTH, errorMessages.minLength('Name'))
        .max(MAX_LENGTH, errorMessages.maxLength('Name')),
    email: z.string().email(errorMessages.invalidEmail()),
    score: z.number().min(0, errorMessages.nonNegativeNumber('Score')).optional(),
    role: z
        .string()
        .min(MIN_LENGTH, errorMessages.minLength('Role'))
        .max(MAX_LENGTH, errorMessages.maxLength('Role')),
});
