import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BEST_STREAK_TEXT, CURRENT_STREAK_TEXT, SUCCESS_RATE_TEXT, TOTAL_TRIES_TEXT, } from '../../constants/strings';
var StatItem = function (_a) {
    var label = _a.label, value = _a.value;
    return (_jsxs("div", { className: "m-1 w-1/4 items-center justify-center dark:text-white", children: [_jsx("div", { className: "text-3xl font-bold", children: value }), _jsx("div", { className: "text-xs", children: label })] }));
};
export var StatBar = function (_a) {
    var gameStats = _a.gameStats;
    return (_jsxs("div", { className: "my-2 flex justify-center", children: [_jsx(StatItem, { label: TOTAL_TRIES_TEXT, value: gameStats.totalGames }), _jsx(StatItem, { label: SUCCESS_RATE_TEXT, value: "".concat(gameStats.successRate, "%") }), _jsx(StatItem, { label: CURRENT_STREAK_TEXT, value: gameStats.currentStreak }), _jsx(StatItem, { label: BEST_STREAK_TEXT, value: gameStats.bestStreak })] }));
};
