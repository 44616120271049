import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { hasOneHourPassed } from '@/src/utils/words';
import { useEffect } from 'react';
var CheckForHour = function (_a) {
    var children = _a.children, setIsOneHourPassed = _a.setIsOneHourPassed;
    useEffect(function () {
        var interval = setInterval(function () {
            if (hasOneHourPassed()) {
                setIsOneHourPassed(true);
            }
        }, 2500);
        return function () {
            clearInterval(interval);
        };
    }, [setIsOneHourPassed]);
    return _jsx(_Fragment, { children: children });
};
export default CheckForHour;
