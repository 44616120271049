import { useEffect } from 'react';
import { REVEAL_TIME_MS } from '../constants/settings';
import { WIN_MESSAGES } from '../constants/strings';
var useGameStatus = function (_a) {
    var isGameLost = _a.isGameLost, isGameWon = _a.isGameWon, setIsStatsModalOpen = _a.setIsStatsModalOpen, showSuccessAlert = _a.showSuccessAlert, solution = _a.solution;
    useEffect(function () {
        if (isGameWon) {
            var winMessage = WIN_MESSAGES[Math.floor(Math.random() * WIN_MESSAGES.length)];
            // const delayMs = REVEAL_TIME_MS * solution.length
            var delayMs = REVEAL_TIME_MS;
            showSuccessAlert(winMessage, {
                delayMs: delayMs,
                onClose: function () { return setIsStatsModalOpen(true); },
            });
        }
        if (isGameLost) {
            setTimeout(function () {
                setIsStatsModalOpen(true);
            }, (solution.length + 1) * REVEAL_TIME_MS);
        }
    }, [isGameWon, isGameLost, solution, showSuccessAlert, setIsStatsModalOpen]);
};
export default useGameStatus;
