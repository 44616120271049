export var GAME_TITLE = process.env.REACT_APP_GAME_NAME;
export var WIN_MESSAGES = ['Great Job!', 'Awesome', 'Well done!'];
export var GAME_COPIED_MESSAGE = 'Game copied to clipboard';
export var NOT_ENOUGH_LETTERS_MESSAGE = 'Not enough letters';
export var WORD_NOT_FOUND_MESSAGE = 'Word not found';
export var HARD_MODE_ALERT_MESSAGE = 'Hard Mode can be enabled only at the start!';
export var HARD_MODE_DESCRIPTION = 'Any revealed hints must be used in subsequent guesses';
export var HIGH_CONTRAST_MODE_DESCRIPTION = 'For improved color vision';
export var CORRECT_WORD_MESSAGE = function (solution) {
    return "The word was ".concat(solution);
};
export var WRONG_SPOT_MESSAGE = function (guess, position) {
    return "Must use ".concat(guess, " in position ").concat(position);
};
export var NOT_CONTAINED_MESSAGE = function (letter) {
    return "Guess must contain ".concat(letter);
};
export var ENTER_TEXT = 'Enter';
export var DELETE_TEXT = 'Delete';
export var STATISTICS_TITLE = 'Statistics';
export var GUESS_DISTRIBUTION_TEXT = 'Guess Distribution';
export var NEW_WORD_TEXT = 'New word in';
export var SHARE_TEXT = 'Share';
export var SHARE_FAILURE_TEXT = 'Unable to share the results. This feature is available only in secure contexts (HTTPS), in some or all supporting browsers.';
export var MIGRATE_BUTTON_TEXT = 'Transfer';
export var MIGRATE_DESCRIPTION_TEXT = 'Click here to transfer your statistics to a new device.';
export var TOTAL_TRIES_TEXT = 'Total tries';
export var SUCCESS_RATE_TEXT = 'Success rate';
export var CURRENT_STREAK_TEXT = 'Current streak';
export var BEST_STREAK_TEXT = 'Best streak';
export var DISCOURAGE_INAPP_BROWSER_TEXT = "You are using an embedded browser and may experience problems sharing or saving your results. We encourage you rather to use your device's default browser.";
export var DATEPICKER_TITLE = 'Choose a past date';
export var DATEPICKER_CHOOSE_TEXT = 'Choose';
export var DATEPICKER_TODAY_TEXT = 'today';
export var ARCHIVE_GAMEDATE_TEXT = 'Game date';
