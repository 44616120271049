import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { solution, unicodeSplit } from '../../lib/words';
import { Cell } from './Cell';
export var CurrentRow = function (_a) {
    var guess = _a.guess, className = _a.className;
    var splitGuess = unicodeSplit(guess);
    var emptyCells = Array.from(Array(solution.length - splitGuess.length));
    var classes = "flex justify-center mb-1 ".concat(className);
    return (_jsxs("div", { className: classes, children: [splitGuess.map(function (letter, i) { return (_jsx(Cell, { value: letter }, i)); }), emptyCells.map(function (_, i) { return (_jsx(Cell, {}, i)); })] }));
};
