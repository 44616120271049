import { jsx as _jsx } from "react/jsx-runtime";
import { getGuessStatuses } from '../../lib/statuses';
import { unicodeSplit } from '../../lib/words';
import { Cell } from './Cell';
export var CompletedRow = function (_a) {
    var solution = _a.solution, guess = _a.guess, isRevealing = _a.isRevealing;
    var statuses = getGuessStatuses(solution, guess);
    var splitGuess = unicodeSplit(guess);
    return (_jsx("div", { className: "mb-1 flex justify-center", children: splitGuess.map(function (letter, i) { return (_jsx(Cell, { value: letter, status: statuses[i], position: i, isRevealing: isRevealing, isCompleted: true }, i)); }) }));
};
