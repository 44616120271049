var _a;
import { addDays, differenceInDays, formatISO, parseISO, startOfDay, } from 'date-fns';
import { default as GraphemeSplitter } from 'grapheme-splitter';
import queryString from 'query-string';
import { ENABLE_ARCHIVED_GAMES } from '../constants/settings';
import { NOT_CONTAINED_MESSAGE, WRONG_SPOT_MESSAGE } from '../constants/strings';
import { VALID_GUESSES } from '../constants/validGuesses';
import { WORDS } from '../constants/wordlist';
import { LAST_WORD, LAST_WORD_DATE, drawWord, hasOneHourPassed, } from '../utils/words';
import { getToday } from './dateutils';
import { getGuessStatuses } from './statuses';
// 1 January 2022 Game Epoch
export var firstGameDate = new Date(2022, 0);
export var periodInDays = 1;
export var isWordInWordList = function (word) {
    return (WORDS.includes(localeAwareLowerCase(word)) ||
        VALID_GUESSES.includes(localeAwareLowerCase(word)));
};
export var isWinningWord = function (word) {
    return solution === word;
};
// build a set of previously revealed letters - present and correct
// guess must use correct letters in that space and any other revealed letters
// also check if all revealed instances of a letter are used (i.e. two C's)
export var findFirstUnusedReveal = function (word, guesses) {
    if (guesses.length === 0) {
        return false;
    }
    var lettersLeftArray = new Array();
    var guess = guesses[guesses.length - 1];
    var statuses = getGuessStatuses(solution, guess);
    var splitWord = unicodeSplit(word);
    var splitGuess = unicodeSplit(guess);
    for (var i = 0; i < splitGuess.length; i++) {
        if (statuses[i] === 'correct' || statuses[i] === 'present') {
            lettersLeftArray.push(splitGuess[i]);
        }
        if (statuses[i] === 'correct' && splitWord[i] !== splitGuess[i]) {
            return WRONG_SPOT_MESSAGE(splitGuess[i], i + 1);
        }
    }
    // check for the first unused letter, taking duplicate letters
    // into account - see issue #198
    var n;
    for (var _i = 0, splitWord_1 = splitWord; _i < splitWord_1.length; _i++) {
        var letter = splitWord_1[_i];
        n = lettersLeftArray.indexOf(letter);
        if (n !== -1) {
            lettersLeftArray.splice(n, 1);
        }
    }
    if (lettersLeftArray.length > 0) {
        return NOT_CONTAINED_MESSAGE(lettersLeftArray[0]);
    }
    return false;
};
export var unicodeSplit = function (word) {
    return new GraphemeSplitter().splitGraphemes(word);
};
export var unicodeLength = function (word) {
    return unicodeSplit(word).length;
};
export var localeAwareLowerCase = function (text) {
    return process.env.REACT_APP_LOCALE_STRING
        ? text.toLocaleLowerCase(process.env.REACT_APP_LOCALE_STRING)
        : text.toLowerCase();
};
export var localeAwareUpperCase = function (text) {
    return process.env.REACT_APP_LOCALE_STRING
        ? text.toLocaleUpperCase(process.env.REACT_APP_LOCALE_STRING)
        : text.toUpperCase();
};
export var getLastGameDate = function (today) {
    var t = startOfDay(today);
    var daysSinceLastGame = differenceInDays(firstGameDate, t) % periodInDays;
    return addDays(t, -daysSinceLastGame);
};
export var getNextGameDate = function (today) {
    return new Date().setMinutes(0) + 3600000;
};
export var isValidGameDate = function (date) {
    if (date < firstGameDate || date > getToday()) {
        return false;
    }
    return differenceInDays(firstGameDate, date) % periodInDays === 0;
};
export var getIndex = function (gameDate) {
    var start = firstGameDate;
    var index = -1;
    do {
        index++;
        start = addDays(start, periodInDays);
    } while (start <= gameDate);
    return index;
};
export var getWordOfHour = function (index) {
    if (index < 0) {
        throw new Error('Invalid index');
    }
    var currentDate = new Date();
    var maxIndex = WORDS.length - 1;
    var lastWord = localStorage.getItem(LAST_WORD);
    var lastWordDate = localStorage.getItem(LAST_WORD_DATE);
    var usedIndexes = [];
    var generateNewIndex = function () {
        var newIndex = Math.floor(Math.random() * (maxIndex + 1));
        if (usedIndexes.length === maxIndex + 1) {
            usedIndexes = [];
        }
        if (usedIndexes.includes(newIndex)) {
            return generateNewIndex();
        }
        return newIndex;
    };
    var wordIndex = generateNewIndex();
    var newWord = localeAwareUpperCase(WORDS[wordIndex]);
    if (lastWord === null || lastWordDate === null) {
        return drawWord({
            currentDate: currentDate,
            newWord: newWord,
            usedIndexes: usedIndexes,
            wordIndex: wordIndex,
        });
    }
    if (hasOneHourPassed()) {
        return drawWord({
            currentDate: currentDate,
            newWord: newWord,
            usedIndexes: usedIndexes,
            wordIndex: wordIndex,
        });
    }
    return lastWord;
};
export var getSolution = function (gameDate) {
    var nextGameDate = getNextGameDate(gameDate);
    var index = getIndex(gameDate);
    var wordOfTheHour = getWordOfHour(index);
    return {
        solution: wordOfTheHour,
        solutionGameDate: gameDate,
        solutionIndex: index,
        hour: nextGameDate.valueOf(),
    };
};
export var getGameDate = function () {
    if (getIsLatestGame()) {
        return getToday();
    }
    var parsed = queryString.parse(window.location.search);
    try {
        var d = startOfDay(parseISO(parsed.d.toString()));
        if (d >= getToday() || d < firstGameDate) {
            setGameDate(getToday());
        }
        return d;
    }
    catch (e) {
        console.log(e);
        return getToday();
    }
};
export var setGameDate = function (d) {
    try {
        if (d < getToday()) {
            window.location.href = '/?d=' + formatISO(d, { representation: 'date' });
            return;
        }
    }
    catch (e) {
        console.log(e);
    }
    window.location.href = '/';
};
export var getIsLatestGame = function () {
    if (!ENABLE_ARCHIVED_GAMES) {
        return true;
    }
    var parsed = queryString.parse(window.location.search);
    return parsed === null || !('d' in parsed);
};
export var solution = (_a = getSolution(getGameDate()), _a.solution), solutionGameDate = _a.solutionGameDate, solutionIndex = _a.solutionIndex, hour = _a.hour;
