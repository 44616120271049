import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DuplicateIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { copyTextToClipboard } from '../../lib/clipboard';
import { encrypt } from '../../lib/encryption';
import { loadGameStateFromLocalStorage } from '../../lib/localStorage';
import { loadStats } from '../../lib/stats';
export var EmigratePanel = function () {
    var _a = useState(true), isCopyButtonEnabled = _a[0], setIsCopyButtonEnabled = _a[1];
    var _b = useState('Copy'), copyButtonText = _b[0], setCopyButtonText = _b[1];
    var stats = loadStats();
    var gameState = loadGameStateFromLocalStorage(true);
    var migrationStats = {
        statistics: stats,
        gameState: gameState,
    };
    var emigrationCode = encrypt(JSON.stringify(migrationStats));
    var copyEmigrationCodeToClipboard = function () {
        copyTextToClipboard(emigrationCode);
        setCopyButtonText('Copied!');
        setIsCopyButtonEnabled(false);
    };
    return (_jsxs("div", { className: "text-sm text-gray-500 dark:text-gray-300", children: [_jsx("label", { htmlFor: "message", className: "mb-2 block text-left text-sm font-medium text-gray-900 dark:text-gray-400", children: "Copy your migration code:" }), _jsx("textarea", { id: "emigration-code", readOnly: true, rows: 8, className: "block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500", value: emigrationCode }), _jsxs("button", { disabled: !isCopyButtonEnabled, onClick: copyEmigrationCodeToClipboard, type: "button", className: "mt-2 inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-left text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:border-gray-200\n          disabled:bg-white disabled:text-gray-900 disabled:focus:outline-none disabled:dark:border-gray-600 disabled:dark:bg-gray-800 disabled:dark:text-gray-400 sm:text-sm", children: [isCopyButtonEnabled && (_jsx(DuplicateIcon, { className: "mr-2 h-6 w-6 cursor-pointer dark:stroke-white" })), copyButtonText] })] }));
};
