import { enUS } from 'date-fns/locale';
export var MAX_CHALLENGES = 6;
export var ALERT_TIME_MS = 2000;
export var LONG_ALERT_TIME_MS = 10000;
export var REVEAL_TIME_MS = 350;
export var WELCOME_INFO_MODAL_MS = 350;
export var DISCOURAGE_INAPP_BROWSERS = true;
export var ENABLE_MIGRATE_STATS = false;
export var BLOWFISH_KEY = 'xcQUAHsik#Thq&LG*8es2DsZ$3bw^e';
export var BLOWFISH_IV = '#45XmF^w';
export var ENABLE_ARCHIVED_GAMES = false;
export var DATE_LOCALE = enUS;
export var MAX_GAMES_ALLOWED = 5;
