import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ClockIcon } from '@heroicons/react/outline';
import { format } from 'date-fns';
import Countdown from 'react-countdown';
import { DATE_LOCALE, ENABLE_ARCHIVED_GAMES, ENABLE_MIGRATE_STATS, } from '../../constants/settings';
import { ARCHIVE_GAMEDATE_TEXT, GUESS_DISTRIBUTION_TEXT, NEW_WORD_TEXT, STATISTICS_TITLE, } from '../../constants/strings';
import { hour, solutionGameDate } from '../../lib/words';
import { Histogram } from '../stats/Histogram';
import { MigrationIntro } from '../stats/MigrationIntro';
import { StatBar } from '../stats/StatBar';
import { BaseModal } from './BaseModal';
import StatsModalActions from './StatsModalActions';
export var StatsModal = function (_a) {
    var isOpen = _a.isOpen, handleClose = _a.handleClose, solution = _a.solution, guesses = _a.guesses, gameStats = _a.gameStats, isLatestGame = _a.isLatestGame, isGameLost = _a.isGameLost, isGameWon = _a.isGameWon, handleShareToClipboard = _a.handleShareToClipboard, handleShareFailure = _a.handleShareFailure, handleMigrateStatsButton = _a.handleMigrateStatsButton, handleGenerateNewSolution = _a.handleGenerateNewSolution, isHardMode = _a.isHardMode, isDarkMode = _a.isDarkMode, isHighContrastMode = _a.isHighContrastMode, numberOfGuessesMade = _a.numberOfGuessesMade, isOneHourPassed = _a.isOneHourPassed;
    if (gameStats.totalGames <= 0) {
        return (_jsxs(BaseModal, { title: STATISTICS_TITLE, isOpen: isOpen, handleClose: handleClose, children: [_jsx(StatBar, { gameStats: gameStats }), ENABLE_MIGRATE_STATS && (_jsx(MigrationIntro, { handleMigrateStatsButton: handleMigrateStatsButton }))] }));
    }
    return (_jsxs(BaseModal, { title: STATISTICS_TITLE, isOpen: isOpen, handleClose: handleClose, children: [_jsx(StatBar, { gameStats: gameStats }), _jsx("h4", { className: "text-lg font-medium leading-6 text-gray-900 dark:text-gray-100", children: GUESS_DISTRIBUTION_TEXT }), _jsx(Histogram, { isLatestGame: isLatestGame, gameStats: gameStats, isGameWon: isGameWon, numberOfGuessesMade: numberOfGuessesMade }), (isGameLost || isGameWon) && (_jsx("div", { className: "mt-5 columns-2 items-center items-stretch justify-center text-center dark:text-white sm:mt-6", children: _jsxs("div", { className: "inline-block w-full text-left", children: [(!ENABLE_ARCHIVED_GAMES || isLatestGame) && (_jsxs("div", { children: [_jsx("h5", { children: NEW_WORD_TEXT }), _jsx(Countdown, { className: "text-lg font-medium text-gray-900 dark:text-gray-100", date: hour, daysInHours: true })] })), ENABLE_ARCHIVED_GAMES && !isLatestGame && (_jsxs("div", { className: "mt-2 inline-flex", children: [_jsx(ClockIcon, { className: "mr-1 mt-2 mt-1 h-5 w-5 stroke-black dark:stroke-white" }), _jsxs("div", { className: "mt-1 ml-1 text-center text-sm sm:text-base", children: [_jsxs("strong", { children: [ARCHIVE_GAMEDATE_TEXT, ":"] }), _jsx("br", {}), format(solutionGameDate, 'd MMMM yyyy', {
                                            locale: DATE_LOCALE,
                                        })] })] }))] }) })), ENABLE_MIGRATE_STATS && (_jsxs("div", { children: [_jsx("hr", { className: "mt-4 -mb-4 border-gray-500" }), _jsx(MigrationIntro, { handleMigrateStatsButton: handleMigrateStatsButton })] })), _jsx(StatsModalActions, { isGameLost: isGameLost, isGameWon: isGameWon, isOneHourPassed: isOneHourPassed, handleGenerateNewSolution: handleGenerateNewSolution, successRate: gameStats.successRate, totalGames: gameStats.totalGames })] }));
};
