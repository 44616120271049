import { jsx as _jsx } from "react/jsx-runtime";
import './index.css';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { AlertProvider } from './context/AlertContext';
import reportWebVitals from './reportWebVitals';
if (process.env.NODE_ENV === 'production') {
    disableReactDevTools();
}
ReactDOM.render(_jsx(React.StrictMode, { children: _jsx(AlertProvider, { children: _jsx(App, {}) }) }), document.getElementById('root'));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
