import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BaseModal } from '../modals/BaseModal';
import { Progress } from '../stats/Progress';
import Spinner from '../ui/Spinner';
var ScoreBoardList = function (_a) {
    var scoreboard = _a.scoreboard;
    if (!scoreboard.length) {
        return _jsx("p", { className: "text-white", children: "No data yet" });
    }
    return (_jsx("ul", { className: "mt-10 w-auto ", children: scoreboard.map(function (player, index) { return (_jsx("li", { className: "text-[16px] text-white", children: _jsx(Progress, { index: index, className: "text-[16px] text-white", isCurrentDayStatRow: false, size: 90 * (player.score / 100), label: player.name + ' - ' + player.score + '%' }) }, player.id)); }) }));
};
var ScoreBoardModal = function (_a) {
    var scoreboard = _a.scoreboard, isOpen = _a.isOpen, handleClose = _a.handleClose, isLoading = _a.isLoading;
    return (_jsx(BaseModal, { title: "Scoreboard", isOpen: isOpen, handleClose: handleClose, children: _jsxs("div", { className: "w-full", children: [isLoading && _jsx(Spinner, { size: "sm" }), !isLoading && _jsx(ScoreBoardList, { scoreboard: scoreboard })] }) }));
};
export default ScoreBoardModal;
