var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { MAX_CHALLENGES } from '../constants/settings';
import { loadStatsFromLocalStorage, saveStatsToLocalStorage, } from './localStorage';
// In stats array elements 0-5 are successes in 1-6 trys
export var addStatsForCompletedGame = function (gameStats, count) {
    // Count is number of incorrect guesses before end.
    var stats = __assign({}, gameStats);
    stats.totalGames += 1;
    if (count >= MAX_CHALLENGES) {
        // A fail situation
        stats.currentStreak = 0;
        stats.gamesFailed += 1;
    }
    else {
        stats.winDistribution[count] += 1;
        stats.currentStreak += 1;
        if (stats.bestStreak < stats.currentStreak) {
            stats.bestStreak = stats.currentStreak;
        }
    }
    stats.successRate = getSuccessRate(stats);
    saveStatsToLocalStorage(stats);
    return stats;
};
var defaultStats = {
    winDistribution: Array.from(new Array(MAX_CHALLENGES), function () { return 0; }),
    gamesFailed: 0,
    currentStreak: 0,
    bestStreak: 0,
    totalGames: 0,
    successRate: 0,
};
export var loadStats = function () {
    return loadStatsFromLocalStorage() || defaultStats;
};
var getSuccessRate = function (gameStats) {
    var totalGames = gameStats.totalGames, gamesFailed = gameStats.gamesFailed;
    return Math.round((100 * (totalGames - gamesFailed)) / Math.max(totalGames, 1));
};
