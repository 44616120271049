import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classnames from 'classnames';
export var SettingsToggle = function (_a) {
    var settingName = _a.settingName, flag = _a.flag, handleFlag = _a.handleFlag, description = _a.description;
    var toggleHolder = classnames('w-14 h-8 flex shrink-0 items-center bg-gray-300 rounded-full p-1 duration-300 ease-in-out cursor-pointer', {
        'bg-green-400': flag,
    });
    var toggleButton = classnames('bg-white w-6 h-6 rounded-full shadow-md transform duration-300 ease-in-out cursor-pointer', {
        'translate-x-6': flag,
    });
    return (_jsx(_Fragment, { children: _jsxs("div", { className: "flex justify-between gap-4 py-3", children: [_jsxs("div", { className: "mt-2 text-left text-gray-500 dark:text-gray-300", children: [_jsx("p", { className: "leading-none", children: settingName }), description && (_jsx("p", { className: "mt-1 text-xs text-gray-500 dark:text-gray-300", children: description }))] }), _jsx("div", { className: toggleHolder, onClick: function () { return handleFlag(!flag); }, children: _jsx("div", { className: toggleButton }) })] }) }));
};
