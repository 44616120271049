var gameStateKey = 'gameState';
var archiveGameStateKey = 'archiveGameState';
var highContrastKey = 'highContrast';
export var saveGameStateToLocalStorage = function (isLatestGame, gameState) {
    var key = isLatestGame ? gameStateKey : archiveGameStateKey;
    localStorage.setItem(key, JSON.stringify(gameState));
};
export var loadGameStateFromLocalStorage = function (isLatestGame) {
    var key = isLatestGame ? gameStateKey : archiveGameStateKey;
    var state = localStorage.getItem(key);
    return state ? JSON.parse(state) : null;
};
var gameStatKey = 'gameStats';
export var saveStatsToLocalStorage = function (gameStats) {
    localStorage.setItem(gameStatKey, JSON.stringify(gameStats));
};
export var loadStatsFromLocalStorage = function () {
    var stats = localStorage.getItem(gameStatKey);
    return stats ? JSON.parse(stats) : null;
};
export var setStoredIsHighContrastMode = function (isHighContrast) {
    if (isHighContrast) {
        localStorage.setItem(highContrastKey, '1');
    }
    else {
        localStorage.removeItem(highContrastKey);
    }
};
export var getStoredIsHighContrastMode = function () {
    var highContrast = localStorage.getItem(highContrastKey);
    return highContrast === '1';
};
