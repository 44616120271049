import { jsx as _jsx } from "react/jsx-runtime";
import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Fragment } from 'react';
export var Alert = function (_a) {
    var isOpen = _a.isOpen, message = _a.message, _b = _a.variant, variant = _b === void 0 ? 'error' : _b, _c = _a.topMost, topMost = _c === void 0 ? false : _c;
    var classes = classNames('fixed z-20 top-15 left-1/2 transform -translate-x-1/2 max-w-sm shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden', {
        'bg-rose-500 text-white': variant === 'error',
        'bg-blue-500 text-white': variant === 'success',
    });
    return (_jsx(Transition, { show: isOpen, as: Fragment, enter: "ease-out duration-300 transition", enterFrom: "opacity-0", enterTo: "opacity-100", leave: "transition ease-in duration-100", leaveFrom: "opacity-100", leaveTo: "opacity-0", children: _jsx("div", { className: classes, children: _jsx("div", { className: "p-2", children: _jsx("p", { className: "text-center text-sm font-medium", children: message }) }) }) }));
};
