import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useCallback, useContext, useState, } from 'react';
import { ALERT_TIME_MS } from '../constants/settings';
export var AlertContext = createContext({
    status: 'success',
    message: null,
    isVisible: false,
    showSuccess: function () { return null; },
    showError: function () { return null; },
});
AlertContext.displayName = 'AlertContext';
export var useAlert = function () { return useContext(AlertContext); };
export var AlertProvider = function (_a) {
    var children = _a.children;
    var _b = useState('success'), status = _b[0], setStatus = _b[1];
    var _c = useState(null), message = _c[0], setMessage = _c[1];
    var _d = useState(false), isVisible = _d[0], setIsVisible = _d[1];
    var show = useCallback(function (showStatus, newMessage, options) {
        var _a = options || {}, _b = _a.delayMs, delayMs = _b === void 0 ? 0 : _b, persist = _a.persist, onClose = _a.onClose, _c = _a.durationMs, durationMs = _c === void 0 ? ALERT_TIME_MS : _c;
        setTimeout(function () {
            setStatus(showStatus);
            setMessage(newMessage);
            setIsVisible(true);
            if (!persist) {
                setTimeout(function () {
                    setIsVisible(false);
                    if (onClose) {
                        onClose();
                    }
                }, durationMs);
            }
        }, delayMs);
    }, [setStatus, setMessage, setIsVisible]);
    var showError = useCallback(function (newMessage, options) {
        show('error', newMessage, options);
    }, [show]);
    var showSuccess = useCallback(function (newMessage, options) {
        show('success', newMessage, options);
    }, [show]);
    return (_jsx(AlertContext.Provider, { value: {
            status: status,
            message: message,
            isVisible: isVisible,
            showError: showError,
            showSuccess: showSuccess,
        }, children: children }));
};
