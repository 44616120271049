import { useEffect, useState } from 'react';
import { getStoredIsHighContrastMode, setStoredIsHighContrastMode, } from '../lib/localStorage';
var useGameSettings = function (guesses, showErrorAlert) {
    var prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
    var _a = useState(localStorage.getItem('gameMode')
        ? localStorage.getItem('gameMode') === 'hard'
        : false), isHardMode = _a[0], setIsHardMode = _a[1];
    var _b = useState(localStorage.getItem('theme')
        ? localStorage.getItem('theme') === 'dark'
        : prefersDarkMode
            ? true
            : false), isDarkMode = _b[0], setIsDarkMode = _b[1];
    var _c = useState(getStoredIsHighContrastMode()), isHighContrastMode = _c[0], setIsHighContrastMode = _c[1];
    useEffect(function () {
        if (isDarkMode) {
            document.documentElement.classList.add('dark');
        }
        else {
            document.documentElement.classList.remove('dark');
        }
        if (isHighContrastMode) {
            document.documentElement.classList.add('high-contrast');
        }
        else {
            document.documentElement.classList.remove('high-contrast');
        }
    }, [isDarkMode, isHighContrastMode]);
    var handleDarkMode = function (isDark) {
        setIsDarkMode(isDark);
        localStorage.setItem('theme', isDark ? 'dark' : 'light');
    };
    var handleHardMode = function (isHard) {
        if (guesses.length === 0 || localStorage.getItem('gameMode') === 'hard') {
            setIsHardMode(isHard);
            localStorage.setItem('gameMode', isHard ? 'hard' : 'normal');
        }
        else {
            showErrorAlert('Hard mode can only be enabled at the start of the game.');
        }
    };
    var handleHighContrastMode = function (isHighContrast) {
        setIsHighContrastMode(isHighContrast);
        setStoredIsHighContrastMode(isHighContrast);
    };
    return {
        isDarkMode: isDarkMode,
        isHardMode: isHardMode,
        isHighContrastMode: isHighContrastMode,
        handleDarkMode: handleDarkMode,
        handleHardMode: handleHardMode,
        handleHighContrastMode: handleHighContrastMode,
    };
};
export default useGameSettings;
